<app-header>
    <div class="hero">
        <h2 class="text-center"><small>{{ calculationContent?.Hero?.Subtitle | render | async }}</small></h2>
        <h1 class="display-1 text-center">{{ calculationContent?.Hero?.Title | render | async }}</h1>
        <div class="hero-image">
            <div class="img">
                <app-image [imageContentModel]="calculationContent?.Hero?.Image"
                           [fetchPriority]="FetchPriorityEnum.High"
                           [loading]="LoadingEnum.Eager" [imageClass]="'img'"></app-image>
            </div>
        </div>
        <div class="mt-3 short-description text-center"
             [innerHTML]="calculationContent?.Hero?.Description | render | async"></div>
        <div class="call-to-actions text-center">
        <a href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted) }}"
           class="mt-3 btn btn-white btn-lg  mb-8">{{ calculationContent?.Hero?.CallToAction | render | async }}
            <i class="bi bi-arrow-right"></i>
        </a>
    </div>
    </div>
</app-header>

<div class="container">
    <div class="row main-feature">
        <div class="col-12 col-md-8">
            <h2 class="display-6"> {{ calculationContent?.MainFeature?.Title | render | async }}</h2>
            <div class="mt-4" [innerHTML]="calculationContent?.MainFeature?.Description | render | async">
            </div>
        </div>
        <div class="col-12 col-md-4">
            <app-image [imageContentModel]="calculationContent?.MainFeature?.Image"
                       [fetchPriority]="FetchPriorityEnum.Low" [loading]="LoadingEnum.Lazy"
                       [imageClass]="'img-fluid'"></app-image>
        </div>
    </div>

    <div class="sub-features row">
        @let numberOfSteps = (calculationContent?.MainFeature?.Steps ?? []).length - 1;
        @let numberOfColumns = Math.ceil(12 / numberOfSteps);
        @for (step of calculationContent?.MainFeature?.Steps ?? []; track $index) {
            <div class="feature col-12 mb-5" [className]="'col-md-' + numberOfColumns">
                <div class="badge rounded-pill bg-secondary">{{ $index + 1 }}</div>
                <h3 class="h6 mt-3">{{ step.Title | render | async }}</h3>
                <div class="mt-4" [innerHTML]="step.Description | render | async"></div>
            </div>
        }
    </div>

    @if (calculationKeyname === CalculationKeynameEnum.SalaryDividend) {
        <div class="alert alert-warning mt-6">
            <h4 class="alert-heading">{{ RemunerationSalaireOuDividendesContentResources.OwnABusiness.Title | render | async }}</h4>
            <div class="alert-description mt-3"
                 [innerHTML]="RemunerationSalaireOuDividendesContentResources?.OwnABusiness.Description | render | async"></div>
            <a class="btn btn-light"
               [routerLink]="this.routeManager.getRouteInCurrentLocale(RouteEnum.ServiceSalaryVsDividend)">{{ RemunerationSalaireOuDividendesContentResources.OwnABusiness.CallToAction | render | async }}</a>
        </div>
    }
    <hr>

    @for (feature of calculationContent?.Features ?? []; track $index) {

        <div class="feature row mt-5">
            <div class="col-12 col-md-8" [class.order-md-first]="$odd" [class.order-md-last]="$even">
                <h2>{{ feature.Title | render | async }}</h2>
                <div class="mt-3 feature-description" [innerHTML]="feature.Description | render | async"></div>
                <a class="btn btn-link mt-5 p-0"
                   href="{{ this.routeManager.getRouteInCurrentLocale(RouteEnum.GetStarted) }}"> {{ feature.CallToAction | render | async }}
                    <i class="bi bi-arrow-right"></i>
                </a>
            </div>
            <div class="col-12 col-md-4">
                <app-image [imageContentModel]="feature.Image" [fetchPriority]="FetchPriorityEnum.Low"
                           [loading]="LoadingEnum.Lazy" [imageClass]="'img-fluid'"></app-image>
            </div>
        </div>
    }

</div>