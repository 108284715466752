import {
    AdvancedFormControlDecoratorOptionsInterface
} from "../decorators/advanced-form-control-decorator-options.interface";
import {ClassMetadata, PropertyMetadata} from "@pristine-ts/metadata";
import {AdvancedFormMetadataConstant} from "../constants/advanced-form-metadata.constant";
import {AdvancedFormArrayDecoratorOptions} from "../decorators/advanced-form-array-decorator.options";
import {
    AdvancedFormGroupDecoratorOptionsInterface
} from "../decorators/advanced-form-group-decorator-options.interface";

export class AdvancedFormMetadata {

    static addFormPropertyKey(options: unknown, propertyKey: string): void {
        ClassMetadata.appendToMetadata(options, AdvancedFormMetadataConstant.PROPERTY_KEYS, propertyKey);
    }

    static getTranslationBaseKey(options: unknown): string {
        return ClassMetadata.getMetadata(options, AdvancedFormMetadataConstant.TRANSLATION_BASE_KEY);
    }

    static setTranslationBaseKey(options: unknown, translationBaseKey: string): void {
        ClassMetadata.defineMetadata(options, AdvancedFormMetadataConstant.TRANSLATION_BASE_KEY, translationBaseKey);
    }

    static getFormProperties(options: unknown): string[] {
        return ClassMetadata.getMetadata(options, AdvancedFormMetadataConstant.PROPERTY_KEYS);
    }

    static getAdvancedFormControl(options: unknown, propertyKey: string): AdvancedFormControlDecoratorOptionsInterface {
        if (!PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_CONTROL)) {
            throw new Error(`The property ${propertyKey} does not have an '@advancedFormControl' decorator.`);
        }

        return PropertyMetadata.getMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_CONTROL);
    }

    static hasAdvancedFormControl(options: unknown, propertyKey: string): boolean {
        return PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_CONTROL);
    }

    static setAdvancedFormControl(options: unknown, propertyKey: string, formControl: AdvancedFormControlDecoratorOptionsInterface): void {
        AdvancedFormMetadata.addFormPropertyKey(options, propertyKey);

        PropertyMetadata.defineMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_CONTROL, formControl);
    }

    static getAdvancedFormArray(options: unknown, propertyKey: string): AdvancedFormArrayDecoratorOptions {
        if (!PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_ARRAY)) {
            throw new Error(`The property ${propertyKey} does not have an '@advancedFormArray' decorator.`);
        }

        return PropertyMetadata.getMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_ARRAY);
    }

    static hasAdvancedFormArray(options: unknown, propertyKey: string): boolean {
        return PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_ARRAY);
    }

    static setAdvancedFormArray(options: unknown, propertyKey: string, advancedFormArray: AdvancedFormArrayDecoratorOptions): void {
        AdvancedFormMetadata.addFormPropertyKey(options, propertyKey);

        PropertyMetadata.defineMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_ARRAY, advancedFormArray);
    }

    static getAdvancedFormGroup(options: unknown, propertyKey: string): AdvancedFormGroupDecoratorOptionsInterface {
        if (!PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_GROUP)) {
            throw new Error(`The property ${propertyKey} does not have an '@advancedFormGroup' decorator.`);
        }

        return PropertyMetadata.getMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_GROUP);
    }

    static hasAdvancedFormGroup(options: unknown, propertyKey: string): boolean {
        return PropertyMetadata.hasMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_GROUP);
    }

    static setAdvancedFormGroup(options: unknown, propertyKey: string, advancedFormGroup: AdvancedFormGroupDecoratorOptionsInterface): void {
        AdvancedFormMetadata.addFormPropertyKey(options, propertyKey);

        PropertyMetadata.defineMetadata(options, propertyKey, AdvancedFormMetadataConstant.ADVANCED_FORM_GROUP, advancedFormGroup);
    }

    static cloneAdvancedFormMetadata(importTarget: any, target: any): void {

    }
}