import {AdvancedFormControlDecoratorOptionsInterface} from "./advanced-form-control-decorator-options.interface";
import {AdvancedFormArrayDecoratorOptions} from "./advanced-form-array-decorator.options";
import {AdvancedFormMetadata} from "../metadatas/advanced-form.metadata";

export const advancedFormArray = (advancedFormArray: AdvancedFormArrayDecoratorOptions, ) => {
    return (
        target: Object,
        propertyKey: string | symbol,
    ) => {
        AdvancedFormMetadata.setAdvancedFormArray(target, propertyKey.toString(), advancedFormArray);
    }
}