import {RouterModule, Routes} from '@angular/router';
import {NgModule, Type} from '@angular/core';
import {LayoutComponent} from './components/layout/layout.component';
import {HomeComponent} from './pages/home/home.component';
import {LocaleEnum} from "./enums/locale.enum";
import {RouteEnum} from "./enums/route.enum";
import {TermsAndConditionsComponent} from "./pages/terms-and-conditions/terms-and-conditions.component";
import {CalculationsComponent} from "./pages/calculations/calculations.component";
import {ContactUsComponent} from "./pages/contact-us/contact-us.component";
import {PricingComponent} from "./pages/pricing/pricing.component";
import {StudentsComponent} from "./pages/students/students.component";
import {AboutUsComponent} from "./pages/about-us/about-us.component";
import {SecurityComponent} from "./pages/security/security.component";
import {GetStartedComponent} from "./pages/get-started/get-started.component";
import {CalculationComponent} from "./pages/calculation/calculation.component";
import {CalculationKeynameEnum} from "../../../../../common/src/enums/calculations/calculation-keyname.enum";
import {SupportComponent} from "./pages/support/support.component";
import {SupportArticleEnum} from "./content/enums/support-article.enum";
import {SupportArticleComponent} from "./pages/support-article/support-article.component";
import {Error404Component} from "./pages/error-404/error-404.component";
import {ContentResourceInterface} from "./content/interfaces/content-resource.interface";
import {IndexContentResources} from "./content/resources/index.content-resources";
import {AboutUsContentResources} from "./content/resources/about-us.content-resources";
import {CalculationsContentResources} from "./content/resources/calculations.content-resources";
import {
    RemunerationSalaireOuDividendesContentResources
} from "./content/resources/remuneration-salaire-ou-dividendes.content-resources";
import {
    DetentionAutomobilePersonnelleOuCorporativeContentResources
} from "./content/resources/detention-automobile-personnelle-ou-corporative.content-resources";
import {RoulementContentResources} from "./content/resources/roulement.content-resources";
import {VenteActionsContentResources} from "./content/resources/vente-actions.content-resources";
import {ContactUsContentResources} from "./content/resources/contact-us.content-resources";
import {TermsAndConditionsContentResources} from "./content/resources/terms-and-conditions.content-resources";
import {PricingContentResources} from "./content/resources/pricing.content-resources";
import {StudentsContentResources} from "./content/resources/students.content-resources";
import {SecurityContentResources} from "./content/resources/security.content-resources";
import {GetStartedContentResources} from "./content/resources/get-started.content-resources";
import {SupportContentResources} from "./content/resources/support.content-resources";
import {RolloverContentResources} from "./content/resources/articles/rollover.content-resources";
import {SalaryVsDividendComponent} from "./pages/services/salary-vs-dividend/salary-vs-dividend.component";
import {
    SalaryVsDividendServiceContentResources
} from "./content/resources/salary-vs-dividend-service.content-resources";

export type LocalizedRoute = {
    component: Type<any>,
    paths: { [locale in LocaleEnum]: string }
    contentResource: ContentResourceInterface;
}

export type CalculationLocalizedRoute = {
    calculations: {
        [id in CalculationKeynameEnum]?: LocalizedRoute;
    }
}

export type SupportLocalizedRoute = {
    articles: {
        [id in SupportArticleEnum]?: LocalizedRoute;
    }
}

export type RoutesDictionary =
    { [id in Exclude<RouteEnum, RouteEnum.Calculation | RouteEnum.SupportArticle>]: LocalizedRoute; } &
    { [RouteEnum.Calculation]: CalculationLocalizedRoute } &
    { [RouteEnum.SupportArticle]: SupportLocalizedRoute };

export const routesDictionary: RoutesDictionary = {
    [RouteEnum.Home]: {
        component: HomeComponent,
        paths: {
            [LocaleEnum.English]: "en",
            [LocaleEnum.French]: "",
        },
        contentResource: IndexContentResources,
    },
    [RouteEnum.AboutUs]: {
        component: AboutUsComponent,
        paths: {
            [LocaleEnum.English]: "about-us",
            [LocaleEnum.French]: "a-propos-de-nous",
        },
        contentResource: AboutUsContentResources,
    },
    [RouteEnum.Calculations]: {
        component: CalculationsComponent,
        paths: {
            [LocaleEnum.English]: "tax-tools",
            [LocaleEnum.French]: "outils-fiscaux",
        },
        contentResource: CalculationsContentResources,
    },
    [RouteEnum.Calculation]: {
        calculations: {
            [CalculationKeynameEnum.SalaryDividend]: {
                component: CalculationComponent,
                paths: {
                    [LocaleEnum.English]: "tax-tools/salary-vs-dividends",
                    [LocaleEnum.French]: "outils-fiscaux/remuneration-salaire-ou-dividendes",
                },
                contentResource: RemunerationSalaireOuDividendesContentResources,
            },
            [CalculationKeynameEnum.VehicleOwnership]: {
                component: CalculationComponent,
                paths: {
                    [LocaleEnum.English]: "tax-tools/vehicle-ownership",
                    [LocaleEnum.French]: "outils-fiscaux/detention-automobile-personelle-ou-corporative",
                },
                contentResource: DetentionAutomobilePersonnelleOuCorporativeContentResources,
            },
            [CalculationKeynameEnum.Rollover]: {
                component: CalculationComponent,
                paths: {
                    [LocaleEnum.English]: "tax-tools/tax-rollover",
                    [LocaleEnum.French]: "outils-fiscaux/roulement-fiscal",
                },
                contentResource: RoulementContentResources,
            },
            [CalculationKeynameEnum.Section84_1]: {
                component: CalculationComponent,
                paths: {
                    [LocaleEnum.English]: "tax-tools/shares-selling",
                    [LocaleEnum.French]: "outils-fiscaux/vente-actions",
                },
                contentResource: VenteActionsContentResources,
            },
        }
    },
    [RouteEnum.ContactUs]: {
        component: ContactUsComponent,
        paths: {
            [LocaleEnum.English]: "contact-us",
            [LocaleEnum.French]: "contactez-nous",
        },
        contentResource: ContactUsContentResources,
    },
    [RouteEnum.TermsAndConditions]: {
        component: TermsAndConditionsComponent,
        paths: {
            [LocaleEnum.English]: "terms-and-conditions",
            [LocaleEnum.French]: "conditions-utilisation",
        },
        contentResource: TermsAndConditionsContentResources,
    },
    [RouteEnum.Pricing]: {
        component: PricingComponent,
        paths: {
            [LocaleEnum.English]: "pricing",
            [LocaleEnum.French]: "tarifs",
        },
        contentResource: PricingContentResources,
    },
    [RouteEnum.Students]: {
        component: StudentsComponent,
        paths: {
            [LocaleEnum.English]: "students",
            [LocaleEnum.French]: "etudiants",
        },
        contentResource: StudentsContentResources,
    },
    [RouteEnum.Security]: {
        component: SecurityComponent,
        paths: {
            [LocaleEnum.English]: "security",
            [LocaleEnum.French]: "securite",
        },
        contentResource: SecurityContentResources,
    },
    [RouteEnum.GetStarted]: {
        component: GetStartedComponent,
        paths: {
            [LocaleEnum.English]: "get-started",
            [LocaleEnum.French]: "debuter-maintenant",
        },
        contentResource: GetStartedContentResources,
    },
    [RouteEnum.Support]: {
        component: SupportComponent,
        paths: {
            [LocaleEnum.English]: "en/support",
            [LocaleEnum.French]: "support",
        },
        contentResource: SupportContentResources,
    },
    [RouteEnum.SupportArticle]: {
        articles: {
            [SupportArticleEnum.SuccessfullyOrchestrateRolloversInHarmonyWithCanadianAndQuebecTaxLaws]: {
                component: SupportArticleComponent,
                paths: {
                    [LocaleEnum.English]: "en/support/articles/successfully-orchestrate-rollovers-in-harmony-with-canadian-and-quebec-tax-laws",
                    [LocaleEnum.French]: "support/articles/reorganisez-avec-succes-maitrisez-les-roulements-en-harmonie-avec-les-lois-fiscales-canadiennes-et-quebecoises",
                },
                contentResource: RolloverContentResources,
            }
        }
    },
    [RouteEnum.ServiceSalaryVsDividend]: {
        component: SalaryVsDividendComponent,
        paths: {
            [LocaleEnum.English]: "services/salary-vs-dividend",
            [LocaleEnum.French]: "services/salaire-vs-dividendes",
        },
        contentResource: SalaryVsDividendServiceContentResources,
    }
}

const getRoutesForAngularRouter = (): Routes => {
    const routes: Routes = [];

    for (const routeKey in routesDictionary) {
        const routeEnum: RouteEnum = routeKey as unknown as RouteEnum;


        switch (routeEnum) {
            case RouteEnum.Calculation: {
                const route = routesDictionary[routeEnum];
                const calculations = route.calculations;

                for (const calculationKeyname in calculations) {
                    const calculation = calculations[calculationKeyname as CalculationKeynameEnum]!;
                    const component = calculation.component
                    const paths = calculation.paths;
                    for (const pathKey in paths) {
                        routes.push({
                            component,
                            path: paths[pathKey as LocaleEnum],
                            data: {
                                id: routeEnum,
                                locale: pathKey as LocaleEnum,
                                calculationKeyname,
                            }
                        })
                    }

                }
            }
                break;
            case RouteEnum.SupportArticle: {
                const route = routesDictionary[routeEnum];
                const articles = route.articles;
                for (const articleKeyname in articles) {
                    const article = articles[articleKeyname as SupportArticleEnum]!;
                    const component = article.component
                    const paths = article.paths;
                    for (const pathKey in paths) {
                        routes.push({
                            component,
                            path: paths[pathKey as LocaleEnum],
                            data: {
                                id: routeEnum,
                                locale: pathKey as LocaleEnum,
                                articleKeyname,
                            }
                        })
                    }

                }
            }
                break;
            default: {
                const route = routesDictionary[routeEnum];
                const component = route.component
                const paths: { [locale in LocaleEnum]: string } = routesDictionary[routeEnum].paths;

                for (const pathKey in paths) {
                    routes.push({
                        component,
                        path: paths[pathKey as LocaleEnum],
                        data: {
                            id: routeEnum,
                            locale: pathKey as LocaleEnum,
                        }
                    })
                }
            }
        }
    }

    return routes;
}

export const routes: Routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            ...getRoutesForAngularRouter(),
        ]
    },
    {
        component: Error404Component,
        path: '**'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
