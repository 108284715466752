import { Component } from '@angular/core';
import {
  SalaryVsDividendServiceContentResources
} from "../../../content/resources/salary-vs-dividend-service.content-resources";
import {BaseComponent} from "../../../components/base.component";
import {LocaleManager} from "../../../managers/locale.manager";
import {RouteManager} from "../../../managers/route.manager";
import {ContentManager} from "../../../content/managers/content.manager";
import {Meta, Title} from "@angular/platform-browser";
import {FetchPriorityEnum} from "../../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../../enums/loading.enum";

@Component({
  selector: 'app-salary-vs-dividend',
  templateUrl: './salary-vs-dividend.component.html',
  styleUrl: './salary-vs-dividend.component.scss'
})
export class SalaryVsDividendComponent extends BaseComponent {

  constructor(
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta,) {
    super(localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
        SalaryVsDividendServiceContentResources,
    )
  }


  protected readonly SalaryVsDividendServiceContentResources = SalaryVsDividendServiceContentResources;
  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly LoadingEnum = LoadingEnum;
}
