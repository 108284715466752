import {
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsInt,
  Max,
  Min,
  ValidateNested, IsOptional, IsDate, ValidateIf
} from "@pristine-ts/class-validator";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";
import { v4 as uuid } from 'uuid';
import { advancedFormControl } from "../../../../decorators/advanced-form-control.decorator";
import { translationBaseKey } from "../../../../decorators/translation-base-key.decorator";
import {ErrorMessageBuilder} from "../../../../builders/error-message.builder";
import { advancedFormArray } from "../../../../decorators/advanced-form-array.decorator";
import { IncomeTaxPersonalSpouseFederalOptions } from "./federal/income-tax-personal-spouse-federal.options";
import { IncomeTaxPersonalSpouseQuebecOptions } from "./quebec/income-tax-personal-spouse-quebec.options";
import {advancedFormGroup} from "../../../../decorators/advanced-form-group.decorator";
import {type} from "@pristine-ts/data-mapping-common";
import {CalculationDate} from "../../../../models/calculation.date";
import {Type} from "class-transformer";

@translationBaseKey("in-options")
export class IncomeTaxPersonalSpouseOptions {
  @advancedFormControl({
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  id: string = uuid();

  @advancedFormControl({
    labelTitle: {
      fr: "Prénom",
      en: "First name"
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  firstName: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Nom de famille",
      en: "Last name"
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.String
  })
  @IsString({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  lastName: string;

  @advancedFormControl({
    labelTitle: {
      fr: "Date de naissance",
      en: "Birthdate"
    },
    tooltip: {
      fr: "Indiquez la date de naissance. La date de naissance est utilisée pour déterminer l'admissibilité à certains crédits d'impôt.",
      en: "Specify the birthdate. The birthdate is used to determine eligibility for certain tax credits.",
    },
    fieldType: FieldTypeEnum.CalculationDate
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @ValidateNested()
  @type(() => new CalculationDate())
  birthdate: CalculationDate;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant de revenu d'emploi",
      en: "Employment income"
    },
    tooltip: {
      fr: "Indiquez le montant du revenu d'emploi de votre conjoint. Le revenu d'emploi peut avoir des répercussions sur les déductions fiscales.",
      en: "Specify your spouse's employment income. Employment income may have implications for tax deductions.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  employmentIncome: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant réel de dividende déterminé reçu",
      en: "Actual amount of eligible dividend received"
    },
    tooltip: {
      fr: "Indiquez le montant réel de dividende déterminé reçu par votre conjoint. Ce montant est utilisé pour les calculs fiscaux.",
      en: "Specify the actual amount of eligible dividends received by your spouse. This amount is used for tax calculations.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  realEligibleDividend: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant réel de dividende ordinaire reçu",
      en: "Actual amount of common dividend received"
    },
    tooltip: {
      fr: "Indiquez le montant réel de dividende ordinaire reçu par votre conjoint. Ce montant est utilisé pour les calculs fiscaux.",
      en: "Specify the actual amount of common dividends received by your spouse. This amount is used for tax calculations.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  realNonEligibleDividend: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Montant de revenu d'entreprise",
      en: "Business income"
    },
    tooltip: {
      fr: "Indiquez le montant du revenu d'entreprise de votre conjoint. Le revenu d'entreprise peut avoir des répercussions sur les déductions fiscales.",
      en: "Specify your spouse's business income. Business income may have implications for tax deductions.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  businessIncome: number = 0;

  @type(() => new IncomeTaxPersonalSpouseFederalOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalSpouseFederalOptions
  })
  @ValidateNested()
  @IsOptional()
  federal?: IncomeTaxPersonalSpouseFederalOptions;

  @type(() => new IncomeTaxPersonalSpouseQuebecOptions())
  @advancedFormGroup({
    classType: IncomeTaxPersonalSpouseQuebecOptions
  })
  @ValidateNested()
  @IsOptional()
  quebec?: IncomeTaxPersonalSpouseQuebecOptions;

  @advancedFormControl({
    labelTitle: {
      fr: "Gain en capital (perte en capital en négatif)",
      en: "Negative capital gain or loss"
    },
    tooltip: {
      fr: "Indiquez le montant du gain en capital ou de la perte en capital (négatif) sur la disposition d'actions côtés en bourse, de biens amortissables, d'obligations et autres. Ces montants peuvent avoir un impact sur les impôts.",
      en: "Specify the amount of negative capital gain or loss on the disposition of publicly traded stocks, depreciable property, bonds, and similar items. These amounts may have tax implications.",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  gainPubliclyTradeShares: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Autres revenus à inclure à la ligne 13000",
      en: "Other income to include on line 13000"
    },
    tooltip: {
      fr: "La ligne 13000 (Autres revenus) est une catégorie fourre-tout pour divers types de revenus : Cette ligne est utilisée pour déclarer tout revenu imposable qui ne rentre pas dans les autres catégories de revenus spécifiques répertoriées sur le formulaire de déclaration de revenus.\n" +
          "Exemples de revenus déclarés à la ligne 13000 :\n" +
          " - Indemnités de départ\n" +
          " - Paiements forfaitaires\n" +
          " - Avantages décès\n" +
          " - Paiements d'un Régime enregistré d'épargne-études (REEE)\n" +
          " - Autres revenus non déclarés ailleurs",
      en: "Line 13000 (Other Income) is a catch-all for various income types: This line is used to report any taxable income that doesn't fit into the other specific income categories listed on the tax return form.\n" +
          "Examples of income reported on line 13000:\n" +
          " - Retiring allowances\n" +
          " - Lump-sum payments\n" +
          " - Death benefits\n" +
          " - Payments from a Registered Education Savings Plan (RESP)\n" +
          " - Other income not reported elsewhere",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  othersIncomesLine13000: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que vous êtes admissible à l'allocation canadienne pour les travailleurs?",
      en: "Are you eligible for the Canada workers benefit?"
    },
    tooltip: {
      fr: "Indiquez si votre conjoint est admissible à l'allocation canadienne pour les travailleurs. L'admissibilité à cette allocation peut avoir des répercussions sur les impôts.",
      en: "Specify whether your spouse is eligible for the Canada workers benefit. Eligibility for this benefit may have tax implications.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isEligibleToCWB: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que le salarié assujetti à l'assurance-emploi?",
      en: "Is the employee subject to employment insurance?"
    },
    tooltip: {
      fr: "Indiquez si le salarié est assujetti à l'assurance-emploi. Cela peut avoir un impact sur les déductions fiscales.",
      en: "Specify whether the employee is subject to employment insurance. This may have implications for tax deductions.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isEmployeeSubjectToEI: boolean;

  @advancedFormControl({
    labelTitle: {
      fr: "Salaire pour le calcul de l'assurance-emploi",
      en: "Salary for employment insurance calculus"
    },
    tooltip: {
      fr: "",
      en: "",
    },
    fieldType: FieldTypeEnum.Money
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional()
  employmentIncomeForEI?: number = 0; // When being used by Salarydividend, this will be passed. todo: use validation groups to determine when this should be set or not
}

