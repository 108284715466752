import {TextContentModel} from "../models/text.content-model";
import {ContentResourceInterface} from "../interfaces/content-resource.interface";
import {ImageContentModel} from "../models/image.content-model";
import {FeatureResourceInterface} from "../interfaces/feature-resource.interface";
import {MarkdownContentModel} from "../models/markdown.content-model";
import {FrequentlyAskedQuestionsInterface} from "../interfaces/frequently-asked-questions.interface";

export const SalaryVsDividendServicePrice = new TextContentModel({
    fr: "249$",
    en: "$249"
})

export const SalaryVsDividendServiceContentResources: {
    Features: FeatureResourceInterface[],
    FrequentlyAskedQuestions: FrequentlyAskedQuestionsInterface,
} & ContentResourceInterface & { [id in string]: any } = {
    LastModified: new TextContentModel(
        {"fr": "2024-12-15", "en": "2024-12-15"}
    ),

    Title: new TextContentModel(
        {"fr": "Salaire ou dividendes - Vous avez une entreprise?",
            "en": "Salary vs Dividend - Do you have a business?"}
    ),

    Meta: {
        Description: new TextContentModel(
            {
                "fr": "Une entreprise québécoise qui crée des outils pour les cabinets comptables d’ici. Optitax est une entreprise québécoise pionnière dans le développement d’outils informatiques fiscaux. Nous vous permettons d’optimiser des situations fiscales complexes en quelques clics. Notre logiciel avancé effectue des dizaines de milliers de simulations pour déterminer la solution la plus optimale selon la situation précise de votre client. Optitax vous offre également l’option de télécharger les formulaires électroniques gouvernementaux remplis en bonne et due forme afin de vous faire économiser encore plus de temps. Ce n’est qu’un début. Ne tardez pas et commencez à utiliser Optitax dès maintenant.\n",
                "en": "Contact Optitax to get tailored tax solutions that suit your needs. Our experienced team is here to answer all  your questions and help you optimize your tax management. Whether you're an individual, a business, or a student,  we offer a comprehensive range of customized packages, including free solutions for students.\n"
            }
        ),

        Keywords: new TextContentModel(
            {
                "fr": "Optitax, Contactez-nous, Logiciel de gestion fiscale, Expertise fiscale, Solutions fiscales personnalisées, Assistance fiscale, Conseils fiscaux, Contactez-nous pour vos besoins fiscaux, Gestion fiscale efficace, Planification fiscale, Conformité fiscale, Services fiscaux professionnels, Optimisation fiscale, Réglementation fiscale, Support client pour la gestion fiscale, Demandez une démo gratuite, Partenariat fiscal",
                "en": "Optitax, Contact Us, Tax management software,Tax expertise,Customized tax solutions,Tax assistance,Tax advisory,Contact us for your tax needs,Efficient tax management,Tax planning,Tax compliance,Professional tax services,Tax optimization,Tax regulations,Customer support for tax management,Request a free demo,Tax partnership"
            }
        ),
    },

    Hero: {
        Title: new TextContentModel({
            fr: "Salaire ou dividendes?",
            en: "Salary vs Dividend"
        }),
        Subtitle: new TextContentModel({
            fr: "Vous avez une entreprise ou un commerce et vous voulez réduire votre imposition?",
            en: "Do you have a business or a store and want to reduce your taxes?"
        }),
        Image: new ImageContentModel({
            url: new TextContentModel({
                fr: "/images/illustrations/salary-dividend-commerce.svg",
                en: "/images/illustrations/salary-dividend-commerce.svg",
            })
        })
    },

    Features: [
        {
            Title: new TextContentModel({
                fr: "Entrepreneurs, maximisez vos revenus nets et réduisez vos impôts!",
                en: "Entrepreneurs, Maximize Your Net Income and reduce your Taxes!"
            }),
            Description: new MarkdownContentModel({
                fr: "" +
                    "Êtes-vous un entrepreneur qui se demandez quelle est la meilleure façon de se rémunérer : en **salaire** ou en **dividendes** ? \n\n" +
                    "Avant Optitax, trouver le ratio précis entre salaire et dividendes était une tâche complexe qui nécessitait une analyse fiscale approfondie de plusieurs heures.\n\n" +
                    "Depuis l'arrivée d'Optitax, il est possible de trouver, en **quelques minutes**, le scénario optimal selon **VOTRE** situation fiscale précise, au dollar près, qui réduit le plus le montant d'impôt à payer, pour le même montant dans vos poches.\n\n" +
                    `Pour seulement **${SalaryVsDividendServicePrice.fr}**, Optitax vous offre la possibilité de faire affaire immédiatement avec un fiscaliste certifié par Optitax, **sans aucun engagement monétaire**.\n\n`,
                en: "" +
                    "Are you an entrepreneur wondering what is the best way to pay yourself: **salary** or **dividends**? \n\n" +
                    "Each option has significant tax implications, and without using Optitax, the task is very difficult and also very expensive.\n\n" +
                    "Optitax automatically calculates, the optimal scenario that reduces tax consequences the most.\n\n" +
                    `Thus, by immediately dealing with a tax specialist certified by Optitax, for only **${SalaryVsDividendServicePrice.en}**, you can save thousands of dollars in taxes.\n\n` +
                    "Request a salary dividend analysis immediately, without any monetary commitment.!"
            }),
            Image: new ImageContentModel({
                url: new TextContentModel({
                    fr: "/images/illustrations/cutting-taxes-fr.svg",
                    en: "/images/illustrations/cutting-taxes.svg",
                })
            }),
            CallToAction: new TextContentModel({
                fr: "Effectuer une demande d'analyse salaire dividende immédiatement",
                en: "Request a salary dividend analysis immediately"
            }),
        },
        {
            Title: new TextContentModel({
                fr: "Comment ça fonctionne?",
                en: "How does it work?"
            }),
            Description: new MarkdownContentModel({
                fr: "Le processus pour faire une demande d'analyse salaire-dividende est très simple. Vous n'avez qu'à cliquer sur le bouton ci-dessous et vous serez guidé. \n\n" +
                    "Toutefois, voici ce à quoi vous pouvez vous attendre:\n" +
                    "1. Créez votre compte Optitax.\n" +
                    "2. Remplissez le questionnaire fiscal.\n" +
                    "3. Le **fiscaliste certifié Optitax** vous contactera pour vous donner un aperçu de l'économie d'impôt.\n" +
                    "4. Si vous êtes satisfait, vous paierez la facture en ligne afin d'obtenir les détails de l'analyse. **Vous ne débourserez rien avant cette étape**\n" +
                    "5. **Économisez des milliers de dollars en impôts**",
                en: "The process to request a salary-dividend analysis is very simple. You just have to click on the button below and you will be guided. \n\n" +
                    "However, here's what you can expect:\n" +
                    "1. Create your Optitax account.\n" +
                    "2. Fill out the tax questionnaire.\n" +
                    "3. The **Optitax certified tax specialist** will contact you to give you an overview of the tax savings.\n" +
                    "4. If you are satisfied, you will pay the invoice online to get the analysis details. **You will not pay anything before this step.**\n" +
                    "5. **Save thousands of dollars in taxes**",
            }),
            Image: new ImageContentModel({
                url: new TextContentModel({
                    fr: "/images/illustrations/how-it-works.svg",
                    en: "/images/illustrations/how-it-works.svg",
                })
            }),
            CallToAction: new TextContentModel({
                fr: "Effectuer une demande d'analyse salaire dividende immédiatement",
                en: "Request a salary dividend analysis immediately"
            }),
        },
        {
            Title: new TextContentModel({
                fr: "Un CPA fiscaliste certifié Optitax s'occupera de vous",
                en: "An Optitax certified CPA tax specialist will take care of you"
            }),
            Description: new MarkdownContentModel({
                fr: "Nous vous jumellerons avec un **fiscaliste certifié Optitax** qui est membre de [l'ordre des CPA du Québec](https://cpaquebec.ca/).\n\n" +
                "Notre programme de certification est très rigoureux et nous nous assurons que nos fiscalistes sont à jour avec les dernières lois fiscales et les meilleures pratiques.\n\n" +
                "Faire confiance à un **fiscaliste certifié Optitax**, c'est s'assurer que vous avez un professionnel qui a accès à notre logiciel révolutionnaire.",
                en: "We will match you with an **Optitax certified tax specialist** who is a member of [the Quebec CPA Order](https://cpaquebec.ca/).\n\n" +
                    "Our certification program is very rigorous and we ensure that our tax specialists are up to date with the latest tax laws and best practices.\n\n" +
                    "Trust an **Optitax certified tax specialist**, to ensure that you have a professional who has access to our revolutionary software."
            }),
            Image: new ImageContentModel({
                url: new TextContentModel({
                    fr: "/images/illustrations/certified-cpa.svg",
                    en: "/images/illustrations/certified-cpa.svg",
                })
            }),
            CallToAction: new TextContentModel({
                fr: "Effectuer une demande d'analyse salaire dividende immédiatement",
                en: "Request a salary dividend analysis immediately"
            }),
        },
        {
            Title: new TextContentModel({
                fr: "Combien ça coûte?",
                en: "How much does it cost?"
            }),
            Description: new MarkdownContentModel({
                fr: `Nous offrons une analyse salaire-dividende pour seulement **${SalaryVsDividendServicePrice.fr}**.\n\n` +
                    "Nous croyons tellement en notre produit et en l'économie de temps qu'offre le logiciel Optitax, que nous pouvons vous offrir un prix incroyablement bas.\n\n" +
                    "**Remplir le questionnaire fiscal et faire la demande ne vous engage à rien!**\n\n" +
                    "Vous paierez uniquement si vous êtes satisfait de l'économie d'impôt que vous pourrez réaliser, lorsque nous vous enverrons l'aperçu.",
                en: `We offer a salary-dividend analysis for only **${SalaryVsDividendServicePrice.en}**.\n\n` +
                    "We believe so much in our product and in the time savings that the Optitax software offers, that we can offer you an incredibly low price.\n\n" +
                    "**In addition, you will only pay if you are satisfied with the tax savings you can achieve, when we send you the overview.**",
            }),
            Image: new ImageContentModel({
                url: new TextContentModel({
                    fr: "/images/illustrations/financial-security.svg",
                    en: "/images/illustrations/financial-security.svg",
                })
            }),
            CallToAction: new TextContentModel({
                fr: "Effectuer une demande d'analyse salaire dividende immédiatement",
                en: "Request a salary dividend analysis immediately"
            }),
        }
    ],

    FrequentlyAskedQuestions: {
        Title: new TextContentModel({
            fr: "Foire aux questions",
            en: "Frequently asked questions",
        }),
        Questions: [
            {
                Question: new TextContentModel({
                    fr: "Dois-je payer à l'avance?",
                    en: "Do I have to pay in advance?"
                }),
                Answer: new MarkdownContentModel({
                    fr: "Non!\n\n Remplir le questionnaire fiscal ne vous engage à rien. En plus, avant de payer, vous recevrez du fiscaliste certifié Optitax un aperçu vous indiquant le montant d'impôt économisé comparativement à une solution 100% dividendes ou 100% salaire.\n\n" +
                        "**Vous paierez uniquement afin de consulter les détails.**",
                    en: "No!\n\n You will fill out the tax questionnaire and before paying, you will receive an overview from the Optitax certified tax specialist indicating how much tax you can save compared to a 100% dividend or 100% salary solution. You will only pay when you are ready to consult the details."
                }),
            },
            {
                Question: new TextContentModel({
                    fr: "À combien se chiffre l'économie d'impôts lorsqu'on utilise Optitax?",
                    en: "How much is the tax savings when using Optitax?"
                }),
                Answer: new MarkdownContentModel({
                    fr: "Il est difficile de donner un chiffre précis, car chaque situation est unique. Toutefois, il n'est pas rare de voir des économies de **plusieurs milliers de dollars**. On voit régulièrement entre 5 et 10% d'économie d'impôts comparativement à une solution 100% dividendes ou 100% salaire.",
                    en: "It is difficult to give an exact figure, as each situation is unique. However, it is not uncommon to see savings of several thousand dollars. We regularly see between 5 and 10% tax savings."
                }),
            },
            {
                Question: new TextContentModel({
                    fr: "Comment pouvez-vous offrir un prix aussi bas?",
                    en: "How can you offer such a low price?"
                }),
                Answer: new MarkdownContentModel({
                    fr: "Grâce à notre logiciel révolutionnaire et unique sur le marché, nous permettons aux fiscalistes de réduire leurs tâches manuelles et ainsi, nous pouvons vous offrir un prix réduit grâce aux économies de temps que nous leur offrons.",
                    en: "Thanks to our revolutionary and unique software on the market, we allow tax specialists to reduce their manual tasks and thus, we can offer you a reduced price thanks to the time savings we offer them."
                }),
            },
            {
                Question: new TextContentModel({
                    fr: "En général, auprès des cabinets québécois, quel est le coût d'une analyse salaires vs dividendes effectuées par un fiscaliste membre de l'ordre des CPA?",
                    en: "In general, with Quebec firms, what is the cost of a salary vs dividend analysis performed by a tax specialist who is a member of the CPA order?"
                }),
                Answer: new MarkdownContentModel({
                    fr: `Beaucoup plus que ${SalaryVsDividendServicePrice.fr}! Tout dépendant du cabinet, il n'est pas rare qu'une analyse simple coûte au dessus de 1000$.`,
                    en: `Much more than ${SalaryVsDividendServicePrice.en}! Depending on the firm, it is not uncommon for a simple analysis to cost over 1000$.`
                }),
            },
            {
                Question: new TextContentModel({
                    fr: "Parfait, je suis convaincu. Comment puis-je procédér?",
                    en: "Alright, I'm convinced. How can I proceed?"
                }),
                Answer: new MarkdownContentModel({
                    fr: "Il est très simple. Commencez par visiter ce lien, pour vous créer un compte client. Ensuite, vous serez inviter à remplir un formulaire.\n\n" +
                        "Une fois le formulaire rempli, un fiscaliste vous contactera pour vous donner un aperçu du montant d'impôt que vous allez économisé. Si vous êtes satisfaits et que vous donnez le mandat au fiscaliste certifé Optitax, vous serez invité à payer la facture en ligne afin d'obtenir les détails sur votre situation fiscale optimisée.",
                    en: "It's very simple. Start by visiting this link, to create a customer account. Then, you will be invited to fill out a form.\n\n" +
                        "Once the form is filled out, a tax specialist will contact you to give you an overview of the amount of tax you will pay. If you are satisfied and give the mandate to the Optitax certified tax specialist, you will be invited to pay the invoice online to obtain the details on your optimized tax situation."
                }),
            },
            {
                Question: new TextContentModel({
                    fr: "En combien de temps aurais-je une réponse du fiscaliste certifié Optitax?",
                    en: "How long will it take to get a response from the Optitax certified tax specialist?"
                }),
                Answer: new MarkdownContentModel({
                    fr: "Les fiscalistes certifiés Opttiax répondent très rapidement. Vous pouvez vous attendre à une réponse entre **2 et 3 jours ouvrables**.",
                    en: "Optitax certified tax specialists respond very quickly. You can expect a response between 2 and 3 business days."
                }),
            },

        ]
    }


};