import { Component } from '@angular/core';
import {RouteManager} from "../../managers/route.manager";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "../../components/base.component";
import {LocaleManager} from "../../managers/locale.manager";
import {ContentManager} from "../../content/managers/content.manager";
import {
  IndividualCalculationContentResourceInterface
} from "../../content/interfaces/individual-calculation-content-resource.interface";
import {CalculationKeynameRoutePathConstants} from "../../content/constants/calculation-keyname-route-path.constants";
import {LocaleEnum} from "../../enums/locale.enum";
import {CalculationKeynameEnum} from "../../../../../../../common/src/enums/calculations/calculation-keyname.enum";
import {
  DetentionAutomobilePersonnelleOuCorporativeContentResources
} from "../../content/resources/detention-automobile-personnelle-ou-corporative.content-resources";
import {FetchPriorityEnum} from "../../enums/fetch-priority.enum";
import {LoadingEnum} from "../../enums/loading.enum";
import {
  RemunerationSalaireOuDividendesContentResources
} from "../../content/resources/remuneration-salaire-ou-dividendes.content-resources";
import {RoulementContentResources} from "../../content/resources/roulement.content-resources";
import {VenteActionsContentResources} from "../../content/resources/vente-actions.content-resources";
import {Meta, Title} from "@angular/platform-browser";
import {RouteEnum} from "../../enums/route.enum";

@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrl: './calculation.component.scss'
})
export class CalculationComponent extends BaseComponent {
  calculationKeyname?: CalculationKeynameEnum;

  get calculationPath(): string | undefined {
    return this._calculationPath;
  }

  set calculationPath(value: string) {
    this._calculationPath = value;

    switch (value) {
      case CalculationKeynameRoutePathConstants[LocaleEnum.French][CalculationKeynameEnum.VehicleOwnership]:
      case CalculationKeynameRoutePathConstants[LocaleEnum.English][CalculationKeynameEnum.VehicleOwnership]:
        this.content = DetentionAutomobilePersonnelleOuCorporativeContentResources;
        this.calculationKeyname = CalculationKeynameEnum.VehicleOwnership;
        break;

      case CalculationKeynameRoutePathConstants[LocaleEnum.French][CalculationKeynameEnum.SalaryDividend]:
      case CalculationKeynameRoutePathConstants[LocaleEnum.English][CalculationKeynameEnum.SalaryDividend]:
        this.content = RemunerationSalaireOuDividendesContentResources;
        this.calculationKeyname = CalculationKeynameEnum.SalaryDividend;
        break;

      case CalculationKeynameRoutePathConstants[LocaleEnum.French][CalculationKeynameEnum.Rollover]:
      case CalculationKeynameRoutePathConstants[LocaleEnum.English][CalculationKeynameEnum.Rollover]:
        this.content = RoulementContentResources;
        this.calculationKeyname = CalculationKeynameEnum.Rollover
        break;


      case CalculationKeynameRoutePathConstants[LocaleEnum.French][CalculationKeynameEnum.Section84_1]:
      case CalculationKeynameRoutePathConstants[LocaleEnum.English][CalculationKeynameEnum.Section84_1]:
        this.content = VenteActionsContentResources;
        this.calculationKeyname = CalculationKeynameEnum.Section84_1;
        break;

    }
  }

  private _calculationPath?: string;

  get calculationContent(): IndividualCalculationContentResourceInterface | undefined {
    return this.content as IndividualCalculationContentResourceInterface;
  }

  constructor(
      localeManager: LocaleManager,
      routeManager: RouteManager,
      contentManager: ContentManager,
      titleService: Title,
      meta: Meta,
      private readonly router: Router,
      private readonly activatedRoute: ActivatedRoute,
      ) {
    super(localeManager,
        routeManager,
        contentManager,
        titleService,
        meta,
    )
  }

  override ngOnInit() {
    super.ngOnInit();
    this.registerSubscription(this.activatedRoute.url.subscribe(url => {
      const path = url.at(-1)?.path;

      if(!path) {
        return;
      }

      this.calculationPath = path;
    }));
  }

  protected readonly FetchPriorityEnum = FetchPriorityEnum;
  protected readonly LoadingEnum = LoadingEnum;
  protected readonly Math = Math;
  protected readonly RouteEnum = RouteEnum;
  protected readonly CalculationKeynameEnum = CalculationKeynameEnum;
  protected readonly RemunerationSalaireOuDividendesContentResources = RemunerationSalaireOuDividendesContentResources;
}
