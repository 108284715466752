import { IncomeTaxPersonalChildOptions} from "./income-tax-personal-child.options";
import { IncomeTaxPersonalSpouseOptions} from "./income-tax-personal-spouse.options";
import { advancedFormArray } from "../../../../decorators/advanced-form-array.decorator";
import {
  IsBoolean,
  IsNotEmpty,
  ValidateNested,
  IsInt,
  IsOptional, validateIf
} from "@pristine-ts/class-validator";
import { translationBaseKey } from "../../../../decorators/translation-base-key.decorator";
import { CalculationRunOptions } from "../../calculation-run.options";
import { advancedFormControl } from "../../../../decorators/advanced-form-control.decorator";
import { CalculationKeynameEnum } from "../../../../enums/calculations/calculation-keyname.enum";
import { FieldTypeEnum } from "../../../../enums/field-type.enum";
import {ErrorMessageBuilder} from "../../../../builders/error-message.builder";
import {advancedFormGroup} from "../../../../decorators/advanced-form-group.decorator";
import {array} from "@pristine-ts/data-mapping-common";
import {ValidationGroupEnum} from "../../../../enums/validation-group.enum";

@translationBaseKey("income-tax-personal-options")
export class IncomeTaxPersonalOptions extends CalculationRunOptions {
  @advancedFormControl({})
  calculationKeyname: CalculationKeynameEnum = CalculationKeynameEnum.IncomeTaxPersonal;

  @advancedFormControl({
    labelTitle: {
      fr: "Est-ce que vous êtes mariés ou avez un conjoint de fait selon la LIR?",
      en: "Are you married or do you have a common law partner ?"
    },
    tooltip: {
      fr: "Indiquez si vous avez un partenaire. Le statut de mariage ou de partenariat peut avoir des conséquences fiscales différentes au Québec et au Canada.",
      en: "Specify if you have a partner. The marital or partnership status may have different tax implications in both Quebec and Canada.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  isMarried: boolean;

  @advancedFormGroup({
    classType: IncomeTaxPersonalSpouseOptions,
  })
  @ValidateNested()
  mainPerson: IncomeTaxPersonalSpouseOptions = new IncomeTaxPersonalSpouseOptions();

  @advancedFormGroup({
    classType: IncomeTaxPersonalSpouseOptions,
  })
  @validateIf((options: IncomeTaxPersonalOptions) => {
    return options.isMarried === true
  })
  @ValidateNested()
  @validateIf((options, value) => options.isMarried)
  spouse?: IncomeTaxPersonalSpouseOptions;

  @array(IncomeTaxPersonalChildOptions)
  @advancedFormArray({
    emptyNumberOfChildrenFormToCreate: 0,
    elementClassType: IncomeTaxPersonalChildOptions,
  })
  @ValidateNested()
  children: IncomeTaxPersonalChildOptions[] = [];

  @advancedFormControl({
    labelTitle: {
      fr: "Année fiscale",
      en: "Fiscal year"
    },
    tooltip: {
      fr: "Indiquez l'année fiscale pour laquelle vous effectuez le calcul. L'année fiscale peut influencer les déductions et crédits d'impôt applicables.",
      en: "Specify the fiscal year for which you are performing the calculation. The fiscal year may impact the applicable tax deductions and credits.",
    },
    fieldType: FieldTypeEnum.Number
  })
  @IsInt({buildErrorMessage: ErrorMessageBuilder.build})
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsOptional({groups: [ValidationGroupEnum.SalaryDividend]})
  fiscalYear: number;

  @advancedFormControl({
    labelTitle: {
      fr: "Voulez-vous calculer les crédits et déductions pour frais de garde d’enfants?",
      en: "Do you want to calculate childcare expenses?"
    },
    tooltip: {
      fr: "Indiquez si les frais de garde doivent être calculés. Le Québec et le Canada peuvent offrir des avantages fiscaux ou des crédits pour les frais de garde admissibles engagés par les contribuables.",
      en: "Specify if childcare expenses need to be calculated. Quebec and Canada may provide tax benefits or credits for eligible childcare expenses incurred by taxpayers.",
    },
    fieldType: FieldTypeEnum.Boolean
  })
  @IsNotEmpty({buildErrorMessage: ErrorMessageBuilder.build})
  @IsBoolean({buildErrorMessage: ErrorMessageBuilder.build})
  isCareExpensesCalculated: boolean;
}
