<app-header>
    <div class="row mt-5 mt-lg-2">
        <div class="col-12 d-flex flex-column align-items-center">
            <h1 class="text-center text-white display-5">{{ SalaryVsDividendServiceContentResources.Hero.Title | render | async }}</h1>

            <h2 class="text-center text-white mt-3 h4">{{ SalaryVsDividendServiceContentResources.Hero.Subtitle | render | async }}</h2>
            <app-image class="img img-fluid hero-image mt-5 mb-6"
                       [imageContentModel]="SalaryVsDividendServiceContentResources.Hero.Image"
                       [fetchPriority]="FetchPriorityEnum.High" [loading]="LoadingEnum.Eager"></app-image>
        </div>
    </div>
</app-header>

<div class="container mt-2">
    @for (feature of SalaryVsDividendServiceContentResources.Features; track $index) {
        <div class="row mt-5">
            <h2 class="display-6 mb-4">{{ feature.Title  | render | async }}</h2>

            <div class="d-flex flex-lg-row flex-column align-items-center">
                <div class="col-12 col-lg-6 order-1" [class.order-lg-0]="$even" [class.order-lg-1]="$odd">
                    <div [innerHTML]="feature.Description | render | async"></div>
                    <a class="d-block btn btn-primary btn-lg mt-4">{{ feature.CallToAction | render | async }}</a>
                </div>

                <div class="col-12 col-lg-4 offset-lg-1 order-0 m-auto" [class.order-lg-1]="$even"
                     [class.order-lg-0]="$odd">
                    <app-image class="img img-fluid" [imageContentModel]="feature.Image"
                               [fetchPriority]="FetchPriorityEnum.Low" [loading]="LoadingEnum.Lazy"></app-image>
        </div>
            </div>

    </div>
    }

    <div class="row">
        <h2 class="display-6 mt-5">{{ SalaryVsDividendServiceContentResources.FrequentlyAskedQuestions.Title | render | async }}</h2>

        @for (question of SalaryVsDividendServiceContentResources.FrequentlyAskedQuestions.Questions; track $index) {
            <div class="row mt-4">

                <div class="col-12">
                    <h5>{{ question.Question | render | async }}</h5>
                    <div [innerHTML]="question.Answer | render | async"></div>
                </div>

            </div>
        }
    </div>


    <!--    Cet outil vous permet de simuler différentes combinaisons de salaires et de dividendes pour déterminer la meilleure stratégie de rémunération pour vous et votre entreprise. Vous pourrez ainsi réduire votre impôt et maximiser votre revenu disponible. Ne tardez pas et commencez à utiliser notre outil dès maintenant.-->

    <!--    <p>Nos comptables partneraires certifiés Optitax, grâce à notre logiciel unique au Québec, peuvent vous offrir une analyse salaires dividendes à un coût dramatiquement moins élevé que les cabinets comptables traditionnels.</p>-->

    <!--        <p>Pour 249$, un CPA certifié par Optitax vous fera une analyse salaires dividendes en un temps record.</p>-->

    <!--        <p>En moyenne, Optitax peut vous économiser 5% d’impôts par rapport aux solutions traditionnellement offertes par les cabinets. </p>-->

    <!--    <h3>Comment débuter</h3>-->

    <!--    <h3>Questions fréquemment posées</h3>-->


    <!--    <h4>Dois-je payer à l'avance?</h4>-->
    <!--    <p>Non! Vous allez remplir le formulaire et avant de payer, nous vous montrerons un estimé de combien d'impôts vous pourrez sauver comparativewnent à une solution 100% dividendes ou 100% salaire. Si vous n'êtes pas satisfaits et que vous ne voulez plus poursuivre, vous n'avez rien à payer. Vous paierez uniquement lors de la consultation des détails, une fois que le CPA certifié Optitax aurait validé votre situation.</p>-->

    <!--    <h4>Combien charge en moyenne les fiscalistes CPA pour une analyse salaires vs dividendes?</h4>-->

    <!--    <p>Beaucoup plus que 249$! Tout dépendant du cabinet, il n'est pas rare qu'une analyse simple coûte au dessus de 1000$.</p>-->

    <!--    <h4>Comment pouvez-vous offrir un prix aussi bas?</h4>-->
    <!--    <p>Grâce à notre logiciel révolutionnaire et unique sur le marché, nous permettons aux fiscalistes de réduire leurs tâches manuelles et ainsi, nous pouvons vous offrir un prix réduit grâce aux économies de temps que nous leur offrons.</p>-->

    <!--    <h4>En combine de temps puis-je m'Attendre à avoir des nouvelles du fiscalistes CPA certifié Optitax</h4>-->

    <!--    <h4>Parfait, jE suis convaincu. Comment puis-je procédér?</h4>-->
    <!--    <p> Il est très simple. Commencez par visiter ce lien, pour vous créer un compte client. Ensuite, vous serez inviter à remplir un formulaire. Une fois le formulaire rempli, un fiscaliste vous contactera pour vous donner un aperçu du montant d'impôt que vous allez payé. Si vous êtes satisfaits et que vous donnez le mandat au fiscaliste certifé Optitax, vous serez invité à payer la facture en ligne afin d'obtenir les détails sur votre situation fiscale optimisée.</p>-->


    <!--    <h4>En combien de temps aurais-je une réponse du fiscaliste certifié Optitax?</h4>-->
    <!--    <p>Les fiscalistes certifiés Opttiax répondent très rapidement. Vous pouvez vous attendre à une réponse entre 2 et 3 jours ouvrables.</p>-->


</div>