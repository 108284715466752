import {AdvancedFormControlDecoratorOptionsInterface} from "./advanced-form-control-decorator-options.interface";
import {ClassMetadata, PropertyMetadata} from "@pristine-ts/metadata";
import {AdvancedFormMetadata} from "../metadatas/advanced-form.metadata";

export const advancedFormControl = (formControl: AdvancedFormControlDecoratorOptionsInterface) => {
    return (
        target: Object,
        propertyKey: string | symbol,
    ) => {
        AdvancedFormMetadata.setAdvancedFormControl(target, propertyKey.toString(), formControl);
    }
}