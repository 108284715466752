import {AdvancedFormControlDecoratorOptionsInterface} from "./advanced-form-control-decorator-options.interface";
import {AdvancedFormArrayDecoratorOptions} from "./advanced-form-array-decorator.options";
import {AdvancedFormGroupDecoratorOptionsInterface} from "./advanced-form-group-decorator-options.interface";
import {AdvancedFormMetadata} from "../metadatas/advanced-form.metadata";

export const advancedFormGroup = (advancedFormGroup: AdvancedFormGroupDecoratorOptionsInterface, ) => {
    return (
        target: Object,
        propertyKey: string | symbol,
    ) => {
        AdvancedFormMetadata.setAdvancedFormGroup(target, propertyKey.toString(), advancedFormGroup);
    }
}